<template>
  <div :style="{height: display ? 'auto' : '200vh'}" :class="['page-layout', pageName]">
    <template v-show="display">
      <common-header :navName="navName" class="header" :show="display" />
      <div :class="['page-wraper', pageName]">
        <div :style="{height: display ? 'auto' : '200vh'}" :class="['main-box', pageName]">
          <side-bar :label1="label1" :label2="label2" :navData="navData" @pageName="getPageName" @loading="displayShow" />
          <div class="page-mian">
            <slot></slot>
          </div>
        </div>
      </div>
      <common-footer v-show="display" />
    </template>
    <Service />
    <Loading v-show="!display" />
  </div>
</template>
<script>
import CommonHeader from '@/components/common/Header.vue'
import CommonFooter from '@/components/common/Footer.vue'
import Loading from '@/components/common/Loading.vue'
import Service from '@/components/common/Service.vue'
import SideBar from '@/components/common/SideBar.vue'
export default {
  components: {
    CommonHeader,
    CommonFooter,
    SideBar,
    Loading,
    Service
  },
  data() {
    return {
      display: false,
      pageName: ''
    }
  },
  props: {
    navData: Array,
    label1: String,
    label2: String,
    navName: String
  },
  beforeCreate() {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  },
  methods: {
    displayShow(loading) {
      this.display = !loading

      var Element = document.querySelector('.mobile-header .head')
      if (Element) {
        var headerHeight = Element.offsetHeight
        document.getElementById('app').style.paddingTop = headerHeight + 'px'
      }
    },
    // 获取当前页面名称
    getPageName(pageName) {
      this.pageName = pageName
    }
  }
}
</script>
<style lang="scss" scoped>
.page-layout {
  .page-mian {
    flex: 1;
    margin-left: 0.6rem;
    min-height: 8rem;
  }
}
.page-wraper {
  padding-bottom: 1rem;
  &.Introduction,
  // &.Mechanism,
  &.Media,
  &.member {
    min-height: pxToRem(962);
    background-image: url(../../assets/img/aboutus/introductionbg.jpg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    //background-color: #fafcfc;
    background-position: 0 bottom;
  }
  &.Culture {
    background-image: url(../../assets/img/aboutus/culture_bg.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left bottom;
    padding-bottom: 4.5rem;
  }
}
.header {
  display: block !important;
}
.main-box {
  max-width: 14rem;
  margin: 0 auto 0;
  padding-top: 1.26rem;
  display: flex;
  .content {
    flex: 1;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}
@media only screen and (max-width: $moble_width) {
  .page-layout {
    .main-box {
      width: 100%;
      padding-top: 0;
      padding: 0 15px;
    }
    .about-side-bar {
      display: none;
    }
    .page-mian {
      width: 100%;
      margin: 0;
      min-height: 7rem;
    }
  }
}
</style>
